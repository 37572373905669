<div class="suspected-match">
  <div class="filters zs-display-flex zs-flex-direction-column">
    <span class="filter-radio-button zs-display-flex zs-margin-1-0-0-0">
      <label class="zs-radio" *ngFor="let filter of visibleChartFilters; let i = index">
        <input [value]="filter" [(ngModel)]="selectedFilter" name="selectedFilter" type="radio"
          (ngModelChange)="filterChanged()" [disabled]="matchesLoading" />
        <span class="radio-label" [ngClass]="{ 'radio-margin': i }" radio>{{
          filter.label
          }}</span>
      </label>
    </span>
    <div *ngIf="selectedFilter.value !== 'PAYER'">
      <!-- HCP,HCO filter -->
      <span class="period zs-display-flex">
        <app-zs-textbox class="search-textbox zs-margin-2-0-0-0" [(modelValue)]="searchBy"
          (modelValueChange)="clearSearch($event)" [enableSearch]="'true'" [placeholder]="searchSuspectedMatches"
          (keyup.enter)="searchByChanged()">
        </app-zs-textbox>
        <button class="zs-button zs-button-solid zs-border-rounded-0 search zs-size-s" (click)="searchByChanged()"
          translate>
          {{ searchSuspectedMatches }}
        </button>
        <span class="zs-margin-0-0-0-2">
          <app-multi-select-dropdown class="period-filter-dropdown" #pastFilterSelect
            (selectedOptionsChange)="OnChangeFilter($event)" [dataSource]="periodList" [label]="viewLabel"
            [defaultText]="" [isAddSelectValue]="true" [isRequired]="true" [isMultiple]="false"
            [minOptionsForSearch]="10">
          </app-multi-select-dropdown>
        </span>
      </span>

    </div>

    <div class="zs-margin-1.5-0-1-0" *ngIf="selectedFilter.value !== 'PAYER'">
      <div class="suspected-matches-label zs-row">
        <div class="zs-col-md-6 padding-top">
          <div class="zs-display-flex">
            <h4 class="suspected-match-heading">{{ suspectedMatchesLabel }}</h4>
            <div class="zs-display-flex pagination-block">
              <app-zs-pagination [itemCount]="totalRecords" [currentPage]="page" [pageSize]="pageSize"
                (pageChange)="pageChanged($event)">
              </app-zs-pagination>
            </div>
          </div>
          <div *ngIf="
            (!matches || matches.length === 0) &&
            !matchesLoading &&
            showNoSuspectedMatchesRecordFound
          " class="zs-margin-1-0-0-0 padding-bottom">
            <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
            <span translate>{{ ui_labels.NO_SUSPECTED_MATCHES_FOUND }}</span>
          </div>
        </div>
      </div>

      <div class="ag-grid-component">
        <div class="grid-table-loader">
          <div *ngIf="showAgGridLoader" class="loader">
            <span class="loader-position" appZsLoader [isVisible]="showAgGridLoader" [isWrapper]="false">
              <p class="zs-text-inverse zs-body-small zs-font-weight-semi-bold">
                {{ loadingLabel }}{{ threeDotConstant }}
              </p>
            </span>
          </div>

          <div class="zs-ag-grid zs-custom-scrollbar" *ngIf="selectedFilter.value !== 'PAYER'">
            <ag-grid-angular class="ag-grid-class ag-theme-alpine" [columnDefs]="colDefs" [rowData]="rowData"
              [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" [suppressCellSelection]="true"
              [enableCellTextSelection]="true" [suppressLoadingOverlay]="true" (sortChanged)="onSort($event)"
              [overlayNoRowsTemplate]="noRowsTemplate">
            </ag-grid-angular>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- payer section -->
  <app-payer-wrapper *ngIf="selectedFilter.value == 'PAYER'"></app-payer-wrapper>
</div>