<div class="filter-view zs-col-md-3">
    <section class="zs-margin-1-1-0-0">
        <!-- <div *ngIf="!(filters && appliedFilter.length > 0) && !loading" class="zs-row">
            <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
            <span translate>{{ ui_labels.NO_FILTERS_SELECTED }}</span>
        </div> -->
        <ng-container *ngFor="let filter of filters">
            <div class="zs-row">
                <div class="zs-accordion" [hidden]="!filter.applied">
                    <div class="filter-title" (click)="flipAccordion(!filter.show)">
                        <a class="expand-collapse-icon"><span [ngClass]="
                        filter.show
                          ? 'zs-icon zs-size-xl zs-icon-arrow-up'
                          : 'zs-icon zs-size-xl zs-icon-arrow-down'
                      "></span></a>
                        <span class="filters-label">
                            {{filter.label}}:
                            <span translate>{{ ui_labels.FILTERS }}</span>
                        </span>
                    </div>
                    <div *ngIf="filter.show" class="filter-option-div">
                        <div *ngFor="let field of filter.fields; let idx = index"
                            class="on-hover-show filter-field-div">
                            <div *ngIf="field.type == searchConstants.SELECT_DROPDOWN">
                                <div class="multiselect_dropdown">
                                    <app-multi-select-dropdown [isSelectAllVisible]="true"
                                        [dataSource]="field.datasource" [isMultiple]="true" #filterSearch
                                        (selectedOptionsChange)="flagsChanged($event)"
                                        [isDataSourceTwoWayBindingRequired]="true" [isCheckBoxVisible]="true"
                                        [customstyle]="{ height: '1em' }" [label]="field.displayLabel">
                                    </app-multi-select-dropdown>
                                </div>
                            </div>
                            <div *ngIf="field.type == 'text'">
                                <label>
                                    <app-zs-textbox [hint]="field.displayLabel" class="filter-textbox"
                                        [(modelValue)]=" field.selectedValue" [showOptionalText]="false"
                                        [inputType]="'text'" [placeholder]="' '" (keyup.enter)="applyFilters()">
                                    </app-zs-textbox>
                                </label>
                            </div>
                        </div>
                        <button class="apply-button zs-button zs-button-solid zs-border-rounded-0"
                            (click)="applyFilters()">
                            <span class="button-label" translate>Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>
</div>
<ng-container *ngIf="showTags">
    <ng-container *ngFor="let category of filters; let i = index">
        <ng-container *ngFor="let sub of category.fields">
            <app-zs-tag *ngIf="isFieldApplied(sub) && sub.type != 'checkbox'" dismissable="true"
                [onCloseCallBackFunction]="tagRemovedFunc" [validatefunctionArgs]="[sub]">
                <span tag-value translate>
                    {{ sub.displayLabel
                    }}<span>:
                        <span class="font-strong">{{
                            getFieldValue(sub)
                            }}</span></span>
                </span>
            </app-zs-tag>
            <app-zs-tag *ngIf="isFieldApplied(sub) && sub.type == 'checkbox'" dismissable="true"
                [onCloseCallBackFunction]="tagRemovedFunc" [validatefunctionArgs]="[sub]">
                <span tag-value translate>
                    <span class="font-strong">{{ sub.displayLabel }}</span>
                </span>
            </app-zs-tag>
        </ng-container>
    </ng-container>
</ng-container>
<div class="pagination zs-display-flex zs-flex-direction-row-reverse">
    <app-zs-pagination [itemCount]="recordCount" [currentPage]="page" [pageSize]="pageSize"
        (pageChange)="pageChange($event)">
    </app-zs-pagination>
</div>
<div class="grid-table-loader">
    <div *ngIf="loading" class="loader">
        <span class="loader-position" appZsLoader [isVisible]="loading" [isWrapper]="false">
            <p class="zs-text-inverse zs-body-small zs-font-weight-semi-bold">
                Your results are Loading ...
            </p>
        </span>
    </div>
    <div *ngIf="!(PayerRecords && PayerRecords.length > 0) && !loading" class="zs-row">
        <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
        <strong class="no-records-message" translate>There are no records to display</strong>
    </div>
    <div #cards *ngIf="(PayerRecords && PayerRecords.length > 0)">
        <div class="grid-container">
            <div *ngFor="let result of PayerRecords; let i = index" class="grid-item">
                <div class="zs-master-style card-component card-block">
                    <app-zs-card (click)="goToProfile(result)">
                        <div card-section class="zs-padding-2 card-width">
                            <div class="zs-row">
                                <div class="zs-col-md-1 card-icon-text">
                                    <span *ngIf="result.ETY_TYPE === MDMCommonConstants.PAYER"
                                        class="card-icon zs-icon zs-size-l zs-icon-local-team-fill">
                                    </span>

                                </div>
                                <div class="zs-padding-0-0-0-1 zs-col-md-11 card-icon-text">
                                    <h4 [ngClass]="
                                result.ETY_TYPE === MDMCommonConstants.PAYER
                                  ? 'highlight-hco-account-name'
                                  : 'highlight-hcp-name'
                              " class="zs-margin-0 search-profile-label-name" [attr.title]="result.NAME">
                                        {{
                                        result.PAYER_NAME &&
                                        result.PAYER_NAME.length > profileNameLabellength
                                        ? (result.PAYER_NAME
                                        | slice: 0:profileNameLabellength) + "..."
                                        : result.PAYER_NAME
                                        }}
                                    </h4>
                                    <div>
                                        <p class="zs-body zs-size-extra-small">
                                            <span translate>Match Date :</span>
                                            <span>{{
                                                result.MSTR_LOAD_DT + dateTimeConstants.Z
                                                | date: environment.dateTimeFormat.dateFormats
                                                }}</span>
                                        </p>
                                    </div>
                                    <div class="zs-margin-0-0-1-0" [attr.title]="result.SOURCES">
                                        <span *ngFor="
                                  let source of result.SOURCES;
                                  let i = index
                                ">
                                            <span class="sources-label zs-badge-counter zs-border-rounded-0" [ngStyle]="{
                                    'background-color':
                                      environment.mdmSourceColorCodes[
                                        source.trim().toLowerCase()
                                      ]
                                  }" *ngIf="i < 3">{{ source.trim().toUpperCase() }}</span>
                                            <span *ngIf="i === 3">...</span>
                                        </span>
                                    </div>
                                    <div>
                                        <p class="zs-margin-0 zs-body-small">
                                            {{
                                            result.PAYER_CHANNEL
                                            }}
                                        </p>

                                    </div>
                                    <div class="zs-padding-1-0-1-0 zs-row">
                                        <app-zs-tag nonclickable="true">
                                            <span tag-value translate>
                                                <span class="font-strong">
                                                    {{ result.ETY_TYPE }}
                                                </span>
                                            </span>
                                        </app-zs-tag>
                                    </div>
                                </div>
                            </div>
                            <div class="zs-row">
                                <p class="zs-margin-0 zs-float-left zs-body zs-size-extra-small mdm-id-align">
                                    <span class="truncate">
                                        ID:
                                        <span class="font-strong" [ngClass]="
                                  result.ETY_TYPE === MDMCommonConstants.PAYER
                                    ? 'highlight-hco-mdm-id'
                                    : 'highlight-hcp-mdm-id'
                                ">{{ result.MDM_ID }}</span>
                                    </span>
                                </p>

                            </div>
                        </div>
                    </app-zs-card>
                </div>
            </div>
        </div>
    </div>
</div>