<div class="compare">
<div
  *ngIf="_backto"
  class="_backto"
  [ngClass]="{ 'zs-disabled': loading }"
>
  <button class="zs-button zs-button-solid zs-border-rounded-0" (click)="goToCompare()">
    {{ backToProfile }} {{ _backto }}
  </button>
</div>
<div [ngClass]="{ backto_class: !_backto }">
  <div
    class="return-id"
    [ngClass]="{ 'zs-disabled': loading }"
  >
    <form class="zs-form">
      <span class="zs-margin-0-2-0-0">
        <app-zs-textbox [hint]="firstMdmId" [(modelValue)]="_id" class="zs-display-inline-block"
        [formValidationCallbackFunction]="formValidationFunction" [required]="true"></app-zs-textbox>
      </span>
      <span class="zs-margin-0-2-0-0">
        <app-zs-textbox [hint]="secondMdmId" [(modelValue)]="_returnId" class="zs-display-inline-block"
        [formValidationCallbackFunction]="formValidationFunction" [required]="true" (keyup.enter)="loadCompareData(true)"></app-zs-textbox>
      </span>
      <span (click)="loadCompareData(true)" class="zs-margin-4-0-0-0">
        <button class="zs-button zs-button-solid zs-border-rounded-0 zs-size-s submit-button" translate>
          {{ ui_labels.SUBMIT }}
        </button>
      </span>
    </form>
  </div>
</div>
<div appZsLoader [isVisible]="loading"
  [ngClass]="{ 'zs-loading': loading }"
  class="zs-padding-2 zs-bg-standard zs-box-shadow zs-border-color-default zs-border-rounded compare-table"
>
  <div *ngIf="sources.length === 0 && !loading" class="zs-row" translate>
    <span class="zs-icon zs-icon-warning zs-icon-color-warning"></span>
    {{ ui_labels.NO_COMPARE_DATA_FOUND }}
  </div>

  <div *ngIf="sources.length > 0" class="zs-margin-0-1-0-0">
    <div class="source_key">
      <div *ngFor="let source of sources" class="zs-margin-0-2-0-0">
        <label class="zs-checkbox">
          <input
            type="checkbox"
            [(ngModel)]="source.show"
            (change)="colVisible($event, source)"
          />
          <span checkbox>{{ source.key | uppercase }}</span>
        </label>
      </div>
    </div>
    <div class="zs-ag-grid zs-master-style zs-margin-1">
      <ag-grid-angular
        class="ag-grid-class ag-theme-alpine" [headerHeight]="headerHeight"
        (gridReady)="onGridReady($event)" [tooltipShowDelay]="500"
      ></ag-grid-angular>
    </div>
  </div>
  <h2 class="margin-class" translate>{{ ui_labels.ADVANCED_SEARCH_RESULTS }}</h2>
  <div class="row">
    <div class="column1">
      <div
        class="google-media-search"
        [innerHTML]="gcseSearchBox"
        id="gcse1"
      ></div>
      <div class="google-media-search" [innerHTML]="gcseSearchResults"></div>
    </div>
    <div class="column2" id="gcse2">
      <div
        class="google-media-search"
        [innerHTML]="gcseSearchBox1"
        id="gcse2"
      ></div>
      <div class="google-media-search" [innerHTML]="gcseSearchResults1"></div>
    </div>
  </div>
</div>
</div>
