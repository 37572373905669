import { Component, OnInit, Input } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AppConfig } from '../../../../app/app.config';
import { EnvironmentConfig } from '../../../../app/shared/models/environment';
import { AgGridColumnDefsConstants } from '../../../../app/shared/constants/ag-grid-angular-constants';
import { PlanCellRendererComponent } from '../../payer/plan-cell-renderer/plan-cell-renderer.component';
import {
  PLANTableConstant,
  PayerWrapperConstants
} from 'src/app/mdm/constants/mdm-common-constants';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'
import { MDMCommonConstants } from '../../constants/mdm-common-constants';
import { MDMService } from 'src/app/mdm/mdm.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.less']
})
export class PlanComponent implements OnInit {
  widthList = ['MSTR_MDM_ID', 'START_DATE', 'COUNT'];
  selectedFilter = { value: 'PAYER' }
  gridOptions: object;
  defaultColDef: { [x: string]: string | boolean; };
  environment: EnvironmentConfig;
  noRowsTemplate: string;
  @Input('tableColumns') tableColumns: any;

  constructor(private config: AppConfig, private service: MDMService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();
  }
  sortOrder: string = MDMCommonConstants.DESC;
  sortByColumn: string;
  searchBy: string;
  showAgGridLoader: boolean;
  domLayout = "autoHeight";
  colDefs: ColDef[] = [];
  payerRowData: any[];
  frameworkComponents: any;
  page = 1;
  pageSize: number;
  totalRecords: number;
  planSub: Subscription;

  ngOnInit(): void {
    this.pageSize = this.environment.pageSize;
    this.defaultColDef = {
      [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
      [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
      [AgGridColumnDefsConstants.CELL_CLASS]: 'ag-cell-padding',
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
      [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
    };
    this.gridOptions = {
      [AgGridColumnDefsConstants.DEFAULT_COL_DEF]: this.defaultColDef,
      [AgGridColumnDefsConstants.ROW_HEIGHT]: 56,
      [AgGridColumnDefsConstants.HEADER_HEIGHT]: 56,
      defaultColDef: {
        sortable: true,
        unSortIcon: true
      },
      [AgGridColumnDefsConstants.APPLY_COLUMN_DEF_ORDER]: true,
    };
    this.frameworkComponents = {
      custumizedId: PlanCellRendererComponent,
    };
    this.setColumns();
    this.getPLANTableDetails();
  }

  getPLANTableDetails() {
    this.showAgGridLoader = true;
    if (this.planSub) {
      this.planSub.unsubscribe();
    }
    this.planSub = this.service.getPLANMatches(this.sortByColumn,
      this.sortOrder,
      this.searchBy,
      this.page,
      this.pageSize).subscribe((data) => {
        this.payerRowData = data.data;
        this.totalRecords = data.totalRecords;
        this.showAgGridLoader = false;
      }, (exception) => {
        this.showAgGridLoader = false;
        this.toaster.showError(exception.error.message, '')
      })
  }
  pageChanged(event) {
    this.page = event;
    this.getPLANTableDetails();
  }

  searchPayerByChanged() {
    this.getPLANTableDetails();
  }
  clearSearch(value) {
    if (!value) {
      this.getPLANTableDetails();

    }

  }
  //sort the columns when clicked on table header
  onSort(event) {
    const columnsSortState = event.columnApi.getColumnState();
    let colId: number;
    let colName = '';
    let columnSortOrder = '';
    let i = 0;
    for (const column of columnsSortState) {
      if (column.sort) {
        colId = i + 1;
        colName = column.colId;
        columnSortOrder = column.sort;
        break;
      }
      i++;
    }
    this.sortOrder = columnSortOrder;
    this.sortByColumn = colName;
    this.getPLANTableDetails();
  }

  setColumns() {
    this.showAgGridLoader = true;
    for (const column in this.tableColumns) {
      this.colDefs.push({
        headerName: column.replace(/_/g, ' '),
        field: this.tableColumns[column],
        [AgGridColumnDefsConstants.FLEX]: 1,
        [AgGridColumnDefsConstants.HEADER_TOOLTIP]: column.replace(/_/g, ' '),
        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: this.getCellTooltip(),
        [AgGridColumnDefsConstants.MAX_WIDTH]: this.widthList.includes(this.tableColumns[column])
          ? 180 : this.tableColumns[column] === 'MSTR_PLAN_NAME' ? 500
            : 250,
        sortingOrder: ['asc', 'desc'],
        ...((this.tableColumns[column] == PLANTableConstant.MDM_ID
          ? true
          : this.tableColumns[column] == PLANTableConstant.SUSPECTED_SOURCES
            ? true
            : this.tableColumns[column] == PLANTableConstant.PLAN_NAME ?
              true
              : this.tableColumns[column] == PLANTableConstant.HIGHEST_MATCH_PERCENTAGE
                ? true
                : false) && { cellRenderer: PlanCellRendererComponent }),
        cellRendererParams: {
          [this.tableColumns[column]]: true,
          routerlink: '/mdm/all-records/payer/profile/',
          state: {
            entity: PayerWrapperConstants.MCO,
            return_page: 'suspected',
          },
        },
      });
    }
  }

  getCellTooltip() {
    return (cellParams) => cellParams.value;
  }
  ngOnDestroy(): void {
    this.planSub.unsubscribe();
  }
}


