<div class="payer-suspected-match">
    <div class="filters zs-display-flex zs-flex-direction-column">
        <span class="period zs-display-flex">
            <app-zs-textbox class="search-textbox" [(modelValue)]="searchBy" [enableSearch]="'true'"
                (modelValueChange)="clearSearch($event)" [placeholder]="'Search'"
                (keyup.enter)="searchPayerByChanged()">
            </app-zs-textbox>
            <button class="zs-button zs-button-solid zs-border-rounded-0 search zs-size-s"
                (click)="searchPayerByChanged()" translate>
                Search
            </button>

        </span>
        <div class="zs-margin-1.5-0-1-0">
            <div class="suspected-matches-label zs-row">
                <div class="zs-col-md-6 padding-top">
                    <div class="zs-display-flex">
                        <h4 class="suspected-match-heading">Suspected PBM Matches ({{totalRecords}})</h4>
                        <div class="zs-display-flex pagination-block">
                            <app-zs-pagination [itemCount]="totalRecords" [pageSizeForDropdown]="5" [currentPage]="page"
                                [pageSize]="pageSize" (pageChange)="pageChanged($event)">
                            </app-zs-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ag-grid-component">
                <div class="grid-table-loader">
                    <div *ngIf="showAgGridLoader" class="loader">
                        <span class="loader-position" appZsLoader [isVisible]="showAgGridLoader" [isWrapper]="false">
                        </span>
                    </div>
                    <div class="zs-ag-grid zs-custom-scrollbar">
                        <ag-grid-angular class="ag-theme-alpine" [columnDefs]="colDefs" [rowData]="pbmRowData"
                            (sortChanged)="onSort($event)" [gridOptions]="gridOptions" [domLayout]="domLayout"
                            [suppressCellSelection]="true" [enableCellTextSelection]="true"
                            [frameworkComponents]="frameworkComponents" [suppressLoadingOverlay]="true"
                            [overlayNoRowsTemplate]="noRowsTemplate">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>