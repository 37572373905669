import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { EnvironmentConfig } from 'src/app/shared/models/environment';
import { DateTimeConstants } from '../../../../shared/constants';
import {
  SearchConstants,
  searchSliceDataPipeConstants,
} from '../../../constants/search-constants';
import {
  MDMCommonConstants,
  PayerWrapperConstants,
} from '../../../constants/mdm-common-constants';
import { Router } from '@angular/router';
import { MDMService } from 'src/app/mdm/mdm.service';
import { ToasterNotificationService } from 'src/app/mdm/ngx-toastr/toaster-notification.service'

@Component({
  selector: 'app-pbm-records',
  templateUrl: './pbm-records.component.html',
  styleUrls: ['./pbm-records.component.less'],
})
export class PbmRecordsComponent implements OnInit {
  filters = [
    {
      applied: true,
      show: true,
      keepTagVisible: false,
      label: 'PBM',
      fields: [
        {
          type: 'text',
          displayLabel: 'ID',
          label: 'MDM_ID',
          applied: true,
          selectedValue: '',
        },
        {
          type: 'text',
          displayLabel: 'NAME',
          label: 'NAME',
          applied: true,
          selectedValue: '',
        },
      ],
    },
  ];
  keepFlagsVisible = false;
  MDMCommonConstants = MDMCommonConstants;
  PayerWrapperConstants = PayerWrapperConstants;
  searchConstants = SearchConstants;
  showTags = false;
  profileNameLabellength = 17;
  dateTimeConstants = DateTimeConstants;
  searchSliceDataPipeConstants = searchSliceDataPipeConstants;
  environment: EnvironmentConfig;
  PBMRecords: any;
  recordCount: number = 0;
  loading: boolean = true;
  page = 1;
  pageSize: number;
  constructor(private config: AppConfig,
    private router: Router,
    private service: MDMService,
    private toaster: ToasterNotificationService) {
    this.environment = this.config.getConfigObject();
  }
  ngOnInit(): void {
    this.pageSize = this.environment.pageSize;
    this.getPBMRecords();
  }
  flipAccordion(showAccordion) {
    this.filters.forEach((filter) => (filter.show = showAccordion));
    if (showAccordion) {
      if (this.showTags) {
        this.filters.forEach((filter) => (filter.keepTagVisible = true));
        this.keepFlagsVisible = true;
      }
    }
  }
  goToProfile(profile: any) {
    this.router.navigate(['/mdm/all-records/payer/profile/', profile.MDM_ID], {
      state: { entity: PayerWrapperConstants.PBM, return_page: 'suspected' },
    });
  }

  getPBMRecords() {
    this.loading = true;
    const payload = {
      filters: {
        [PayerWrapperConstants.PBM]: {}
      },
      page: this.page,
      pageSize: this.pageSize
    }
    this.filters[0].fields.forEach((ele) => {
      if (ele.selectedValue != '') {
        payload.filters[PayerWrapperConstants.PBM][ele.label] = ele.selectedValue
      }
    })
    this.service.getPBMRecords(payload).subscribe((resp: any) => {
      this.PBMRecords = resp.data;
      this.recordCount = resp.totalRecords;
      this.loading = false;
    }, (exception) => {
      this.loading = false;

      this.toaster.showError(exception.error.message.name, '');
    })
  }
  applyFilters() {
    this.getPBMRecords();
  }
  pageChange(event) {
    this.page = event;
    this.getPBMRecords();
  }
}
