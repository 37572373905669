import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MDMService } from '../mdm.service';
import { MDMCompare, ProfileCompareConstants } from '../constants/mdm-common-constants';
import { mdm_labels } from '../constants/ui-translation';
import { TranslateService } from '@ngx-translate/core';
import { commmon_labels } from 'src/app/common-label';
import { AgGridColumnDefsConstants } from 'src/app/shared/constants/ag-grid-angular-constants';
import { ToasterDataService } from 'src/app/revo-core/toaster-service/toaster-data.service';
import { FormValidationService } from 'src/app/revo-core/form-validation-service/form-validation-service';

@Component({
    selector: "mdm-compare",
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class CompareComponent implements OnInit {
    _id = '';
    _returnId = '';
    _backto = '';
    _type = '';
    loading: boolean;
    data: any;
    columns: any[] = [];
    sources: any[] = [];
    allIds: any[] = [];
    columnDefs: any[] = [];
    rowData: any[] = [];
    gridApi: any;
    colApi: any;
    counter: number;
    countSource: number;
    firstMdmId: any;
    secondMdmId: any;
    formValidationFunction: Function;
    formErrors: boolean[];

    attributes: string;
    gcseSearchBox: SafeHtml;
    gcseSearchBox1: SafeHtml;

    gcseSearchResults: SafeHtml;
    gcseSearchResults1: SafeHtml;

    searchquery1: string;
    searchquery2: string;

    googleCustomSearchEngineKey: string;
    ui_labels = mdm_labels.ADVANCED_SEARCH;
    commmon_labels = commmon_labels;
    backToProfile: string;
    translated_labels: any;
    error_label: string;
    columnsMapping = new Map<string, string>();
    sourceIdMapping = new Map<number, string>();
    headerHeight = 50;

    constructor(private service: MDMService, private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
      private _translate: TranslateService, private _toasterDataService: ToasterDataService,
      private _formValidationService: FormValidationService) {
        this.translated_labels = this._translate.instant(
            [
                this.ui_labels.BACK_TO_PROFILE,
                this.commmon_labels.ERROR_WITH_COLON,
                this.ui_labels.NAME,
                this.ui_labels.AGE,
                this.ui_labels.SPECIALTY,
                this.ui_labels.EDUCATION,
                this.ui_labels.COLLEGE,
                this.ui_labels.YEAR_OF_PASSING,
                this.ui_labels.NPI,
                this.ui_labels.WORKING_LOCATION,
                this.ui_labels.RESIDENTIAL_LOCATION,
                this.ui_labels.ATTRIBUTES,
                this.ui_labels.MDM_ID1,
                this.ui_labels.MDM_ID2
            ]);
        this.backToProfile = this.translated_labels[this.ui_labels.BACK_TO_PROFILE];
        this.error_label = this.translated_labels[this.commmon_labels.ERROR_WITH_COLON];
        this.attributes = this.translated_labels[this.ui_labels.ATTRIBUTES];
        this.firstMdmId = this.translated_labels[this.ui_labels.MDM_ID1];
        this.secondMdmId = this.translated_labels[this.ui_labels.MDM_ID2];
        this.columnsMapping[ProfileCompareConstants.NAME] = this.translated_labels[this.ui_labels.NAME];
        this.columnsMapping[ProfileCompareConstants.AGE] = this.translated_labels[this.ui_labels.AGE];
        this.columnsMapping[ProfileCompareConstants.SPECIALTY] = this.translated_labels[this.ui_labels.SPECIALTY];
        this.columnsMapping[ProfileCompareConstants.EDUCATION] = this.translated_labels[this.ui_labels.EDUCATION];
        this.columnsMapping[ProfileCompareConstants.COLLEGE] = this.translated_labels[this.ui_labels.COLLEGE];
        this.columnsMapping[ProfileCompareConstants.YEAR_OF_PASSING] = this.translated_labels[this.ui_labels.YEAR_OF_PASSING];
        this.columnsMapping[ProfileCompareConstants.NPI] = this.translated_labels[this.ui_labels.NPI];
        this.columnsMapping[ProfileCompareConstants.WORKING_LOC] = this.translated_labels[this.ui_labels.WORKING_LOCATION];
        this.columnsMapping[ProfileCompareConstants.RESIDENTIAL_LOC] = this.translated_labels[this.ui_labels.RESIDENTIAL_LOCATION];
    }

    ngOnInit() {
        this.gcseSearchBox = this.sanitizer.bypassSecurityTrustHtml('<gcse:searchbox gname=\'g1\' ></gcse:search>');
        this.gcseSearchBox1 = this.sanitizer.bypassSecurityTrustHtml('<gcse:searchbox gname=\'g2\'></gcse:searchbox>');
        this.gcseSearchResults = this.sanitizer.bypassSecurityTrustHtml('<gcse:searchresults gname=\'g1\'></gcse:searchresults>');
        this.gcseSearchResults1 = this.sanitizer.bypassSecurityTrustHtml('<gcse:searchresults gname=\'g2\'></gcse:searchresults>');
      this.getSearchEngineKey();
      this.formValidationFunction = this.formValidation.bind(this);
    }
    colVisible(event, source) {
        for (let i = 0; i < 2; i++) {
            this.colApi.setColumnVisible(`${source.key}${this.allIds[i]}`, source.show);
        }
    }
    onGridReady(event) {
        this.gridApi = event.api;
        this.colApi = event.columnApi;
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.setRowData(this.rowData);
    }
    sourceIdMapper() {
        let index = 0;
        this.sources.forEach(source => {
            this.sourceIdMapping[index] = `${source.key}${this.allIds[0]}`;
            this.sourceIdMapping[index + 1] = `${source.key}${this.allIds[1]}`;
            index = index + 2;
        });
    }
    setRowData() {
        this.rowData = [];
        this.columns.forEach(col => {
            this.counter = 0;
            this.countSource = 0;
            const compareRow = {};
            for (const [k, v] of Object.entries(this.sourceIdMapping)) {
                if (this.counter === 0) {
                    compareRow[ProfileCompareConstants.ATTRIBUTES] = this.columnsMapping[col];
                }
                if (this.counter % 2 === 0) {
                    compareRow[this.sourceIdMapping[this.counter]] = this.data[this.sources[this.countSource].key][this.allIds[0]][col];
                } else {
                    compareRow[this.sourceIdMapping[this.counter]] = this.data[this.sources[this.countSource].key][this.allIds[1]][col];
                    this.countSource = this.countSource + 1;
                }
                this.counter = this.counter + 1;
            }
            this.rowData.push(compareRow);
        });

    }
    setColumnDefs() {
        this.columnDefs = [];
        const attributeCol = {
            [AgGridColumnDefsConstants.HEADER_NAME]: this.attributes,
            [AgGridColumnDefsConstants.FIELD]: ProfileCompareConstants.ATTRIBUTES,
            [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
            [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
            [AgGridColumnDefsConstants.CELL_CLASS]: 'attribute-class'
        };
        this.columnDefs.push(attributeCol);
        this.sources.forEach(source => {
            const compareColumn = {
                [AgGridColumnDefsConstants.FIELD]: source.key.toUpperCase(),
                [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
                [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
                [AgGridColumnDefsConstants.FLEX]: 1,
                [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
                [AgGridColumnDefsConstants.WRAP_TEXT]: true,
                [AgGridColumnDefsConstants.CELL_CLASS]: 'column-class',
                [AgGridColumnDefsConstants.CHILDREN]: [
                    {
                        [AgGridColumnDefsConstants.HEADER_NAME]: this.allIds[0],
                        [AgGridColumnDefsConstants.FIELD]: `${source.key}${this.allIds[0]}`,
                        [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
                        [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
                        [AgGridColumnDefsConstants.FLEX]: 1,
                        [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
                        [AgGridColumnDefsConstants.WRAP_TEXT]: true,
                        [AgGridColumnDefsConstants.MIN_WIDTH]: 105,
                        [AgGridColumnDefsConstants.CELL_CLASS]: 'column-class',
                        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: (cellParams) => {
                          return cellParams.value;
                        }
                    },
                    {
                        [AgGridColumnDefsConstants.HEADER_NAME]: this.allIds[1],
                        [AgGridColumnDefsConstants.FIELD]: `${source.key}${this.allIds[1]}`,
                        [AgGridColumnDefsConstants.SUPPRESS_MENU]: true,
                        [AgGridColumnDefsConstants.SUPPRESS_MOVABLE]: true,
                        [AgGridColumnDefsConstants.AUTO_HEIGHT]: true,
                        [AgGridColumnDefsConstants.WRAP_TEXT]: true,
                        [AgGridColumnDefsConstants.MIN_WIDTH]: 105,
                        [AgGridColumnDefsConstants.FLEX]: 1,
                        [AgGridColumnDefsConstants.CELL_CLASS]: 'column-class',
                        [AgGridColumnDefsConstants.TOOLTIP_VALUE_GETTER]: (cellParams) => {
                          return cellParams.value;
                        }
                    }
                ]
            };
            this.columnDefs.push(compareColumn);
        });
    }
    getSearchEngineKey() {
        this.service.getSearchEngineKey().subscribe(result => {
            let cx = result;
            let gcse = document.createElement('script');
            gcse.type = MDMCompare.GCSE_TYPE;
            gcse.async = true;
            gcse.src = MDMCompare.GCSE_SRC_URL + cx;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(gcse, s);

            this.route.queryParams.subscribe(params => {
                this._returnId = params[MDMCompare.ID1];
                this._id = params[MDMCompare.ID2];
                this._backto = params[MDMCompare.CURRENT_PROFILE_ID];
                this._type = params[MDMCompare.TYPE];

                this.loadCompareData();
            },
                exception => {
                    this.loading = false;
                    this._toasterDataService.addMessageToToaster( exception.error.message);
                });
        },
            exception => {
                this._toasterDataService.addMessageToToaster(exception.error.message);
            });
    }

    resetTableData() {
        this.allIds = [];
        this.data = {};
        this.sources = [];
        this.columns = [];
    }


  loadCompareData(triggeredFromView = false) {
    if (triggeredFromView) {
      this.formErrors = [];
      this._formValidationService.sendMessage('Form Submitted');
      if (!this.formErrors.includes(true)) {
        this.loadData();
      }
    } else {
        this.loadData();
    }
  }

  loadData() {
    if (this._id === undefined && this._returnId === undefined) {
      this._id = '';
      this._returnId = '';
      this.resetTableData();
      this.resetSearchBoxResults();
    } else if ((this._id.trim().length > 0) && (this._returnId.trim().length > 0)) {
        this.resetTableData();

        this.loading = true;

        this.service.postCompareData(this._id, this._returnId, this._type).subscribe(res => {
            this.loading = false;

            if (res) {
                this.data = res.tableData;
                if (res) {
                    this.allIds.push(this._id);
                    this.allIds.push(this._returnId);
                    let tableData = res.tableData;
                    this.sources = Object.keys(tableData).map(k => ({ show: true, key: k }));

                    this.searchquery1 = res.ID1;
                    this.searchquery2 = res.ID2;
                    if (tableData[this.sources[0].key] && tableData[this.sources[0].key][this._id]) {
                        this.columns = Object.keys(tableData[this.sources[0].key][this._id]);
                    }
                    this.sourceIdMapper();
                    this.setColumnDefs();
                    this.setRowData();
                }
            } else {
                this.resetTableData();
            }
            this.loadSearch();
        },
            exception => {
                this.resetSearchBoxResults();
                this.loading = false;
                this._toasterDataService.addMessageToToaster( exception.error.message);
            }
        );
    }
  }

    resetSearchBoxResults() {
        (<HTMLInputElement>document.getElementsByClassName('gscb_a')[0])?.click();
        (<HTMLInputElement>document.getElementsByClassName('gscb_a')[1])?.click();
    }

    loadSearch() {
        this.resetSearchBoxResults();
        (<HTMLInputElement>document.getElementById(MDMCompare.SEARCH_BOX_ID_1)).value = this.searchquery1;
        (<HTMLInputElement>document.getElementById(MDMCompare.SEARCH_BOX_ID_2)).value = this.searchquery2;
        let btn1: HTMLElement = document.getElementsByClassName(MDMCompare.GSC_SEARCH_BUTTON)[0] as HTMLElement;
        btn1.click();
        let btn2: HTMLElement = document.getElementsByClassName(MDMCompare.GSC_SEARCH_BUTTON)[1] as HTMLElement;
        btn2.click();
    }


    goToCompare() {
        if (this._backto && this._type) {
            this.router.navigate(
                [`/${MDMCompare.MDM}/${MDMCompare.ALL_RECORDS}/${this._type.toLowerCase()}/${MDMCompare.PROFILE}/${this._backto}`]);
        }
  }

  formValidation(errrorState: boolean) {
    this.formErrors.push(errrorState);
  }
}

